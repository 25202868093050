.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
    margin: 5px;
}

.btn-circle {
    /*width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;*/


    width: 50px;
    height: 50px;
    padding: 8px 12px;
    border-radius: 25px;
    margin: 5px;
}